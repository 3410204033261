rodijco.controller 'LoginController', [
  '$scope'
  'httpService'
  (scope, httpService) ->

    scope.successfulLogin = null

    # Login
    scope.login = (user) ->
      httpService.post 'user/login', user, (response) ->
        return scope.successfulLogin = false if not response.success

        scope.successfulLogin = true
        window.location = '/'
]
